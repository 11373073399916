import "./index.css";
import { Authenticator, translations } from "@aws-amplify/ui-react";
import { Hub } from "aws-amplify";
import { useEffect, useState } from "react";
import { publicCampaignClient } from "../../../api";
import { I18n } from "aws-amplify";
import AdminFooter from "../../../Components/AdminFooter";
import AdminLoginHeader from "../../../Components/AdminLoginHeader";
import { defaultStorageHelper } from "@spry/campaign-client";
I18n.putVocabularies(translations);

function Login() {
  const [userName, setUserName] = useState(null);
  const [userStatus, setUserStatus] = useState("");

  useEffect(() => {
    defaultStorageHelper.remove("sessionKey");
    defaultStorageHelper.remove("email");
    defaultStorageHelper.remove("won");
    defaultStorageHelper.remove("awardedTime");
    defaultStorageHelper.remove("preferredLanguage");
  }, []);

  useEffect(() => {
    Hub.listen("auth", (data: any) => {
      if (data.payload.message) {
        setUserName(data.payload.message.split(" ")[0]);
        setUserStatus(data.payload.data.message);
      }
    });
  }, [Hub]);

  useEffect(() => {
    customizeValidationErrorMessage();
    customizeForgotTempPasswordErrorMessage();
  }, [userStatus, userName]);

  const customizeValidationErrorMessage = () => {
    const originalMessageForValidationError =
      "2 validation errors detected: Value at 'userAlias' failed to satisfy constraint: Member must satisfy regular expression pattern: [\\p{L}\\p{M}\\p{S}\\p{N}\\p{P}]+; Value at 'userName' failed to satisfy constraint: Member must satisfy regular expression pattern: [\\p{L}\\p{M}\\p{S}\\p{N}\\p{P}]+";
    const translatedMessageForValidationError =
      "Please provide a valid email address as your username.";
    if (
      userStatus ==
      "2 validation errors detected: Value at 'userAlias' failed to satisfy constraint: Member must satisfy regular expression pattern: [\\p{L}\\p{M}\\p{S}\\p{N}\\p{P}]+; Value at 'userName' failed to satisfy constraint: Member must satisfy regular expression pattern: [\\p{L}\\p{M}\\p{S}\\p{N}\\p{P}]+"
    ) {
      I18n.putVocabularies({
        en: {
          [originalMessageForValidationError]: [
            translatedMessageForValidationError,
          ],
        },
      });
    }
  };

  const customizeForgotTempPasswordErrorMessage = async () => {
    const originalMessageForForgotTempPassword =
      "User password cannot be reset in the current state.";
    const translatedMessageForForgotTempPassword =
      "Temporary password has been resent to the user.";
    if (
      userName !== null &&
      userStatus === "User password cannot be reset in the current state."
    ) {
      I18n.putVocabularies({
        en: {
          [originalMessageForForgotTempPassword]: [
            translatedMessageForForgotTempPassword,
          ],
        },
      });
      try {
        await publicCampaignClient.call("signUp", {
          userName: userName,
        });
      } catch (error) {
        console.log(error);
      }
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    }
  };

  return (
    <div className="wrapper">
      <AdminLoginHeader />
      <div className="main">
        <div className="section-form">
          <div className="shell">
            <div className="section__inner">
              <div className="form-login">
                <div className="form__head">
                  <h3>Promotion Login</h3>
                  <p>
                    Please use the login information provided by the promotion
                    administrator below. Not your RBC Employee login.
                  </p>
                </div>
                <div className="form__body">
                  <Authenticator hideSignUp={true} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AdminFooter />
    </div>
  );
}

export default Login;
