import React, { useState, useEffect } from "react";
import AdminFooter from "../../../Components/AdminFooter";
import AdminHeader from "../../../Components/AdminHeader";
import { useForm } from "react-hook-form";
import Modal from "react-modal";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { campaignClient } from "../../../api";
import { emailRegex } from "../../../helpers/regexes";
import Loading from "../../../Components/Loading";
import { defaultStorageHelper } from "@spry/campaign-client";

type RegisterFormData = {
  email: string;
  preferredLanguage: string;
};

const EnterEmail = () => {
  useEffect(() => {
    defaultStorageHelper.remove("sessionKey");
    defaultStorageHelper.remove("email");
    defaultStorageHelper.remove("won");
    defaultStorageHelper.remove("awardedTime");
    defaultStorageHelper.remove("preferredLanguage");
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<RegisterFormData>({ mode: "onChange" });

  const { t } = useTranslation();
  const navigate = useNavigate();
  const [submitting, setSubmitting] = useState(false);
  const [submissionError, setSubmissionError] = useState(false);

  const handleRegister = async (data: RegisterFormData) => {
    if (submitting) {
      return;
    }
    setSubmitting(true);
    setSubmissionError(false);
    try {
      const { sessionKey, email, won, awardedTime, preferredLanguage }: any =
        await campaignClient.call("checkPrizeWinner", {
          email: data.email,
          preferredLanguage: data.preferredLanguage,
        });

      if (sessionKey) {
        defaultStorageHelper.set("sessionKey", sessionKey);
        defaultStorageHelper.set("email", email);
        defaultStorageHelper.set("won", won);
        preferredLanguage
          ? defaultStorageHelper.set("preferredLanguage", preferredLanguage)
          : defaultStorageHelper.set(
              "preferredLanguage",
              data.preferredLanguage
            );
        awardedTime !== undefined
          ? defaultStorageHelper.set(
              "awardedTime",
              `${new Date(awardedTime).toLocaleDateString()} - ${new Date(
                awardedTime
              ).toLocaleTimeString()}`
            )
          : defaultStorageHelper.set("awardedTime", "");
        navigate(`/admin/participant-status`);
      } else {
        setSubmissionError(true);
      }
    } catch {
      setSubmissionError(true);
    }
    setSubmitting(false);
  };

  return (
    <>
      <AdminHeader />
      <div className="main">
        {submissionError ? (
          <div className="main" style={{ minHeight: "100%" }}>
            <section className="section section-full section-main section--center">
              <div className="shell">
                <div className="section__inner section__inner--full">
                  <div className="section__content">
                    <Modal
                      isOpen
                      contentLabel="My dialog"
                      ariaHideApp={false}
                      className="popup"
                      portalClassName="modal"
                      onAfterOpen={() =>
                        (document.body.style.overflow = "hidden")
                      }
                      onAfterClose={() =>
                        (document.body.style.overflow = "unset")
                      }
                      style={{
                        overlay: {
                          zIndex: 1043,
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          backgroundColor: "rgba(0,0,0,0.5)",
                        },
                      }}
                    >
                      <div
                        className="mfp-wrap mfp-close-btn-in mfp-auto-cursor mfp-fade mfp-ready"
                        tabIndex={-1}
                        style={{ overflow: "hidden auto" }}
                      >
                        <div className="mfp-container mfp-ajax-holder mfp-s-ready">
                          <div className="mfp-content">
                            <div className="popup popup--alt popup--small">
                              <div className="popup__inner">
                                <div className="popup__body">
                                  <h4>
                                    Something went wrong, please try again
                                    later.
                                  </h4>
                                </div>
                                <div className="popup__foot">
                                  <a
                                    className="btn btn--default btn--primary js-popup-close popup__btn"
                                    onClick={() =>
                                      navigate("/admin")
                                    }
                                  >
                                    OK
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Modal>
                  </div>
                </div>
              </div>
            </section>
          </div>
        ) : (
          <></>
        )}

        {submitting ? (
          <div className="main" style={{ minHeight: "100%" }}>
            <section className="section section-full section-main section--center">
              <div className="shell">
                <div className="section__inner section__inner--full">
                  <div className="section__content">
                    <Loading />
                  </div>
                </div>
              </div>
            </section>
          </div>
        ) : (
          <div className="section-form section-form--alt">
            <div className="shell">
              <div className="section__inner">
                <div className="subscribe">
                  <form onSubmit={handleSubmit(handleRegister)}>
                    <header className="subscribe__head">
                      <h3>Enter an email</h3>
                      <p>
                        Confirm that the user participated by reviewing their
                        screenshot of the final screen of the experience. Once
                        confirmed, enter the email that a participant presents
                        and click submit.
                      </p>
                    </header>
                    <div className="subscribe__body">
                      <label htmlFor="mail" className="subscribe__label">
                        Email
                      </label>
                      <input
                        type="text"
                        className="subscribe__field field"
                        style={{ marginBottom: "2rem" }}
                        id="field-email"
                        defaultValue=""
                        placeholder="Email"
                        {...register("email", {
                          required: {
                            value: true,
                            message: "Please enter an email.",
                          },
                          pattern: {
                            value: emailRegex,
                            message: "Please enter a valid email.",
                          },
                        })}
                      />
                      {errors.email && (
                        <p className="error-message">
                          <i className="fas fa-exclamation-circle" />{" "}
                          {errors.email.message}
                        </p>
                      )}

                      <label htmlFor="mail" className="subscribe__label">
                        Participant Preferred Language
                      </label>
                      <select
                        style={{ marginBottom: "2rem" }}
                        className="subscribe__field field"
                        id="field-customer"
                        {...register("preferredLanguage", {
                          required: {
                            value: true,
                            message: `${"Please participant preferred language."}`,
                          },
                        })}
                      >
                        <option value="">Select Language</option>
                        <option value="en">English</option>
                        <option value="fr">French</option>
                      </select>
                      {errors.preferredLanguage && (
                        <p className="error-message">
                          <i className="fas fa-exclamation-circle" />{" "}
                          {errors.preferredLanguage.message}
                        </p>
                      )}
                    </div>
                    <input
                      type="submit"
                      defaultValue="Submit"
                      className="subscribe__btn btn"
                    />
                  </form>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <AdminFooter />
    </>
  );
};

export default EnterEmail;
