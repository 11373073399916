import { useTranslation } from "react-i18next";
import { useRequiredCampaignState } from "../../helpers/campaignStates";

export default function Closed() {
  useRequiredCampaignState("closed");
  const { t } = useTranslation();
  return (
    <div className="main">
      <div
        className="hero"
        style={{
          backgroundImage: 'url("assets/images/hero-background.png")',
        }}
      >
        <div className="hero__inner">
          <div className="shell hero__shell">
            <div className="hero__content">
              <h1>{t("closed")}</h1>
            </div>
            <div className="hero__figure">
              <img
                src="assets/images/hero-images.png"
                alt=""
                width={384}
                className="hero__desktop"
              />
              <img
                src="assets/images/hero-images-mobile.png"
                alt=""
                className="hero__mobile"
              />
            </div>
          </div>
          <div className="hero__bottom">
            <img src="assets/images/hero-bottom.png" alt="" />
          </div>
          <div className="hero__image">
            <img
              src="assets/images/hero-borders.png"
              alt=""
              className="hero__desktop"
            />
            <img
              src="assets/images/hero-border-mobile.png"
              alt=""
              className="hero__mobile"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
