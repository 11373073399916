import {
  defaultStorageHelper,
  SPRYCampaignClient,
} from "@spry/campaign-client";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import { campaignClient } from "../../../api";
import AdminFooter from "../../../Components/AdminFooter";
import AdminHeader from "../../../Components/AdminHeader";
import Loading from "../../../Components/Loading";
import { Auth } from "aws-amplify";

const ParticipantStatus = () => {
  const navigate = useNavigate();
  const sessionKey = defaultStorageHelper.get("sessionKey");
  const email = defaultStorageHelper.get("email");
  const awardedTime = defaultStorageHelper.get("awardedTime");
  const preferredLanguage = defaultStorageHelper.get("preferredLanguage");
  const won = defaultStorageHelper.get("won");
  const [submissionError, setSubmissionError] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [adminEmail, setAdminEmail] = useState("");
  const [adminSchoolZone, setAdminSchoolZone] = useState("");

  useEffect(() => {
    if (!sessionKey) {
      navigate("/admin");
    }
    getCogntioAdmin();
  }, []);

  const getCogntioAdmin = async () => {
    let res = await Auth.currentAuthenticatedUser();
    setAdminEmail(res.username);
    setAdminSchoolZone(res.attributes.zoneinfo);
  };

  const handleSubmit = async () => {
    if (!sessionKey) {
      return;
    }
    setSubmitting(true);
    setSubmissionError(false);
    try {
      const { won }: any = await campaignClient.call("instantWin", {
        sessionKey: sessionKey,
        adminEmail: adminEmail,
        adminSchoolZone: adminSchoolZone,
        schoolName: adminSchoolZone,
      });
      if (won && won === true) {
        navigate(`/admin/awarded`);
        return;
      } else {
        setSubmissionError(true);
      }
    } catch (error) {
      setSubmissionError(true);
    }
    setSubmitting(false);
  };

  return (
    <>
      <AdminHeader />
      <div className="main">
        {submissionError ? (
          <div className="main" style={{ minHeight: "100%" }}>
            <section className="section section-full section-main section--center">
              <div className="shell">
                <div className="section__inner section__inner--full">
                  <div className="section__content">
                    <Modal
                      isOpen
                      contentLabel="My dialog"
                      ariaHideApp={false}
                      className="popup"
                      portalClassName="modal"
                      onAfterOpen={() =>
                        (document.body.style.overflow = "hidden")
                      }
                      onAfterClose={() =>
                        (document.body.style.overflow = "unset")
                      }
                      style={{
                        overlay: {
                          zIndex: 1043,
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          backgroundColor: "rgba(0,0,0,0.5)",
                        },
                      }}
                    >
                      <div
                        className="mfp-wrap mfp-close-btn-in mfp-auto-cursor mfp-fade mfp-ready"
                        tabIndex={-1}
                        style={{ overflow: "hidden auto" }}
                      >
                        <div className="mfp-container mfp-ajax-holder mfp-s-ready">
                          <div className="mfp-content">
                            <div className="popup popup--alt popup--small">
                              <div className="popup__inner">
                                <div className="popup__body">
                                  <h4>
                                    Something went wrong, please try again
                                    later.
                                  </h4>
                                </div>
                                <div className="popup__foot">
                                  <a
                                    className="btn btn--default btn--primary js-popup-close popup__btn"
                                    onClick={() => navigate("/admin")}
                                  >
                                    OK
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Modal>
                  </div>
                </div>
              </div>
            </section>
          </div>
        ) : (
          <></>
        )}

        {submitting ? (
          <div className="main" style={{ minHeight: "100%" }}>
            <section className="section section-full section-main section--center">
              <div className="shell">
                <div className="section__inner section__inner--full">
                  <div className="section__content">
                    <Loading />
                  </div>
                </div>
              </div>
            </section>
          </div>
        ) : (
          <div className="section-form section-form--alt">
            <div className="shell">
              <div className="section__inner">
                <div className="subscribe subscribe--alt">
                  <header className="subscribe__head">
                    <h3>Participant details</h3>
                    {won == "true" ? (
                      <p>This participant has already received a reward.</p>
                    ) : (
                      <p>
                        Please review the info below before giving the user
                        their reward.
                      </p>
                    )}
                  </header>
                  <div className="subscribe__body">
                    <ul>
                      <li>
                        <strong>Email:</strong>
                        <a href={`mailto:${email}`}>{email}</a>
                      </li>
                      <li>
                        <strong>Prize:</strong>
                        $10 Gift Card
                      </li>
                      {awardedTime !== "" ? (
                        <li>
                          <strong>Date awarded:</strong>
                          {awardedTime}
                        </li>
                      ) : (
                        <li></li>
                      )}
                      {preferredLanguage !== "" ? (
                        <li>
                          <strong>Participant preferred language:</strong>
                          {preferredLanguage}
                        </li>
                      ) : (
                        <li></li>
                      )}
                    </ul>
                    {won == "false" ? (
                      <div
                        className="subscribe__actions"
                        style={{ marginTop: "5rem" }}
                      >
                        <a
                          className="btn subscribe__btn subscribe__btn--small"
                          onClick={() => handleSubmit()}
                        >
                          Give reward
                        </a>
                        <a
                          className="btn btn--border subscribe__btn subscribe__btn--small"
                          onClick={() => navigate("/admin/enter-email")}
                        >
                          Cancel
                        </a>
                      </div>
                    ) : (
                      <div
                        className="subscribe__actions"
                        style={{ marginTop: "5rem" }}
                      >
                        <a
                          className="btn subscribe__btn subscribe__btn--small"
                          onClick={() => navigate("/admin/enter-email")}
                        >
                          Back
                        </a>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <AdminFooter />
    </>
  );
};

export default ParticipantStatus;
