import { defaultStorageHelper } from "@spry/campaign-client";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import AdminFooter from "../../../Components/AdminFooter";
import AdminHeader from "../../../Components/AdminHeader";
import Loading from "../../../Components/Loading";

const Awarded = () => {
  const navigate = useNavigate();
  const email = defaultStorageHelper.get("email");
  const [submissionError, setSubmissionError] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (!email) {
      navigate("/admin");
    }
  }, []);

  return (
    <>
      <AdminHeader />
      <div className="main">
        {submissionError ? (
          <div className="main" style={{ minHeight: "100%" }}>
            <section className="section section-full section-main section--center">
              <div className="shell">
                <div className="section__inner section__inner--full">
                  <div className="section__content">
                    <Modal
                      isOpen
                      contentLabel="My dialog"
                      ariaHideApp={false}
                      className="popup"
                      portalClassName="modal"
                      onAfterOpen={() =>
                        (document.body.style.overflow = "hidden")
                      }
                      onAfterClose={() =>
                        (document.body.style.overflow = "unset")
                      }
                      style={{
                        overlay: {
                          zIndex: 1043,
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          backgroundColor: "rgba(0,0,0,0.5)",
                        },
                      }}
                    >
                      <div
                        className="mfp-wrap mfp-close-btn-in mfp-auto-cursor mfp-fade mfp-ready"
                        tabIndex={-1}
                        style={{ overflow: "hidden auto" }}
                      >
                        <div className="mfp-container mfp-ajax-holder mfp-s-ready">
                          <div className="mfp-content">
                            <div className="popup popup--alt popup--small">
                              <div className="popup__inner">
                                <div className="popup__body">
                                  <h4>
                                    Something went wrong, please try again
                                    later.
                                  </h4>
                                </div>
                                <div className="popup__foot">
                                  <a
                                    className="btn btn--default btn--primary js-popup-close popup__btn"
                                    onClick={() =>
                                      navigate("/admin")
                                    }
                                  >
                                    OK
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Modal>
                  </div>
                </div>
              </div>
            </section>
          </div>
        ) : (
          <></>
        )}

        {submitting ? (
          <div className="main" style={{ minHeight: "100%" }}>
            <section className="section section-full section-main section--center">
              <div className="shell">
                <div className="section__inner section__inner--full">
                  <div className="section__content">
                    <Loading />
                  </div>
                </div>
              </div>
            </section>
          </div>
        ) : (
          <div className="section-form section-form--alt">
            <div className="shell">
              <div className="section__inner">
                <div className="card-success">
                  <div className="card__image">
                    <img src="assets/images/success.svg" alt="" />
                  </div>
                  <h3>Success!</h3>
                  <p>
                    The reward has now been awarded to{" "}
                    <a href={`mailto:${email}`}>{email}</a>. They will receive
                    an email with their gift card shortly.
                  </p>
                  <a
                    className="btn card__btn"
                    onClick={() => navigate("/admin")}
                  >
                    Done
                  </a>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <AdminFooter />
    </>
  );
};

export default Awarded;
