const AdminLoginHeader = () => {
  return (
    <header className="header header--small">
      <div className="shell">
        <div className="header__inner">
          <a className="logo-small">
            <img src="assets/images/logo-small.png" alt="" width={65} />
          </a>
        </div>
      </div>
    </header>
  );
};

export default AdminLoginHeader;
