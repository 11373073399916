import React from "react";

const AdminFooter = () => {
  return (
    <footer className="footer footer--alt">
      <div className="shell">
        <div className="footer__inner">
          <p>Royal Bank of Canada © 1995-2022</p>
        </div>
      </div>
    </footer>
  );
};

export default AdminFooter;
