import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Modal from "react-modal";
import { publicCampaignClient } from "../../../api";
import Loading from "../../../Components/Loading";
import { defaultStorageHelper } from "@spry/campaign-client";
import schools from "./schools";

type RegisterFormData = {
  langugage: string;
  schoolName: string;
};

const Landing = (inventoryCount: any) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<RegisterFormData>({ mode: "onChange" });

  const navigate = useNavigate();
  const [submitting, setSubmitting] = useState(false);
  const [submissionError, setSubmissionError] = useState(false);
  const [language, setLanguage] = useState("");
  const { t, i18n } = useTranslation();
  const [other, setOther] = useState(false);

  const handleLanguageChange = (lang: string) => {
    defaultStorageHelper.set("lang", lang);
    i18n.changeLanguage(lang);
  };

  useEffect(() => {
    defaultStorageHelper.remove("sessionKey");
    defaultStorageHelper.remove("result");
    defaultStorageHelper.remove("tagLine");
    defaultStorageHelper.remove("resultImage");
    defaultStorageHelper.remove("learnMoreLink");
    defaultStorageHelper.remove("schoolName");
    defaultStorageHelper.remove("schoolLocation");
    defaultStorageHelper.remove("lang");
    defaultStorageHelper.remove("schoolLocationLink");
  }, []);

  const schoolsArray: any = schools.en.sort((a: any, b: any) =>
    a.schoolName > b.schoolName ? 1 : -1
  );

  const schoolsDropDown = schoolsArray.map((school: any) => {
    return (
      <option
        key={school.schoolName}
        value={school.schoolName}
        style={{ color: "#252525" }}
      >
        {school.schoolName === "zOther" && i18n.language == "fr"
          ? school.frSchoolName.substring(1)
          : school.schoolName === "zOther" && i18n.language == "en"
          ? school.schoolName.substring(1)
          : school.schoolName}
      </option>
    );
  });

  const handleRegister = async (data: RegisterFormData) => {
    if (submitting && (language !== "fr" || "en")) {
      return;
    }

    const selectedSchool = schools.en.filter(
      (school: any) => school.schoolName === data.schoolName
    );

    setSubmitting(true);
    setSubmissionError(false);

    try {
      const { sessionKey }: any = await publicCampaignClient.call("register", {
        schoolName: data.schoolName,
        language: language,
        prizeFlow:
          inventoryCount && inventoryCount.inventoryCount <= 0 ? false : true,
      });
      if (sessionKey) {
        defaultStorageHelper.set("sessionKey", sessionKey);
        defaultStorageHelper.set("learnMoreLink", selectedSchool[0].learnMore);
        defaultStorageHelper.set("schoolName", data.schoolName);
        defaultStorageHelper.set("schoolLocation", selectedSchool[0].location);
        defaultStorageHelper.set(
          "schoolLocationLink",
          i18n.language === "en"
            ? selectedSchool[0].locationLink
            : selectedSchool[0].frLocationLink
        );
        navigate(`/trivia-question`);
      } else {
        setSubmissionError(true);
      }
    } catch {
      setSubmissionError(true);
    }
    setSubmitting(false);
  };

  return (
    <div className="main">
      {!inventoryCount && <Loading />}
      {language === "" ? (
        <div className="main" style={{ minHeight: "100%" }}>
          <section className="section section-full section-main section--center">
            <div className="shell">
              <div className="section__inner section__inner--full">
                <div className="section__content">
                  <Modal
                    isOpen
                    contentLabel="My dialog"
                    ariaHideApp={false}
                    className="popup"
                    portalClassName="modal"
                    onAfterOpen={() =>
                      (document.body.style.overflow = "hidden")
                    }
                    onAfterClose={() =>
                      (document.body.style.overflow = "unset")
                    }
                    style={{
                      overlay: {
                        position: "fixed",
                        zIndex: 1043,
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "rgba(0,0,0,0.5)",
                      },
                    }}
                  >
                    <div
                      className="mfp-wrap mfp-close-btn-in mfp-auto-cursor mfp-ready"
                      style={{ overflow: "hidden auto", position: "relative" }}
                    >
                      <div className="mfp-content">
                        <div className="popup">
                          <h4>{t("Language.select")}</h4>
                          <div className="popup__actions">
                            <button
                              className="btn popup__btn"
                              onClick={() => {
                                setLanguage("fr");
                                handleLanguageChange("fr");
                              }}
                              // disabled={i18n.language === "fr" ? true : false}
                            >
                              {t("Language.french")}
                            </button>
                            <button
                              className="btn popup__btn"
                              onClick={() => {
                                setLanguage("en");
                                handleLanguageChange("en");
                              }}
                              // disabled={i18n.language === "en" ? true : false}
                            >
                              {t("Language.english")}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Modal>
                </div>
              </div>
            </div>
          </section>
        </div>
      ) : (
        <></>
      )}

      {submissionError ? (
        <div className="main" style={{ minHeight: "100%" }}>
          <section className="section section-full section-main section--center">
            <div className="shell">
              <div className="section__inner section__inner--full">
                <div className="section__content">
                  <Modal
                    isOpen
                    contentLabel="My dialog"
                    ariaHideApp={false}
                    className="popup"
                    portalClassName="modal"
                    onAfterOpen={() =>
                      (document.body.style.overflow = "hidden")
                    }
                    onAfterClose={() =>
                      (document.body.style.overflow = "unset")
                    }
                    style={{
                      overlay: {
                        zIndex: 1043,
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "rgba(0,0,0,0.5)",
                      },
                    }}
                  >
                    <div
                      className="mfp-wrap mfp-close-btn-in mfp-auto-cursor mfp-fade mfp-ready"
                      tabIndex={-1}
                      style={{ overflow: "hidden auto" }}
                    >
                      <div className="mfp-container mfp-ajax-holder mfp-s-ready">
                        <div className="mfp-content">
                          <div className="popup popup--alt popup--small">
                            <div className="popup__inner">
                              <div className="popup__body">
                                <h4>
                                  {i18n.language === "en"
                                    ? "Something went wrong, please try again later."
                                    : "Quelque chose c'est mal passé. Merci d'essayer plus tard."}
                                </h4>
                              </div>
                              <div className="popup__foot">
                                <a
                                  className="btn btn--default btn--primary js-popup-close popup__btn"
                                  onClick={() => setSubmissionError(false)}
                                >
                                  OK
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Modal>
                </div>
              </div>
            </div>
          </section>
        </div>
      ) : (
        <></>
      )}

      {submitting ? (
        <div className="main" style={{ minHeight: "100%" }}>
          <section className="section section-full section-main section--center">
            <div className="shell">
              <div className="section__inner section__inner--full">
                <div className="section__content">
                  <Loading />
                </div>
              </div>
            </div>
          </section>
        </div>
      ) : (
        <div
          className="hero hero--alt"
          style={{
            backgroundImage: 'url("assets/images/hero-background.png")',
          }}
        >
          <div className="hero__container">
            <div className="hero__inner">
              <div className="shell">
                <div className="hero__content">
                  <h2>{t("landing.t1")}</h2>
                  <h3>{t("landing.t2")}</h3>
                </div>
                <div className="hero__figure">
                  <img
                    src="assets/images/hero-images.png"
                    alt=""
                    width={384}
                    className="hero__desktop"
                  />
                  <img
                    src="assets/images/hero-images-mobile.png"
                    alt=""
                    className="hero__mobile"
                  />
                </div>
              </div>
              <div className="hero__bottom">
                <img src="assets/images/hero-bottom.png" alt="" />
              </div>
              <div className="hero__image hero__mobile">
                <img
                  src="assets/images/hero-border-mobile.png"
                  alt=""
                  className=""
                />
              </div>
            </div>
            <div className="hero__select-holder">
              <div className="shell">
                <div className="hero__inner-alt">
                  {/* {(inventoryCount && inventoryCount.inventoryCount <= 0) ||
                  other ? ( */}
                  {inventoryCount && inventoryCount.inventoryCount <= 0 ? (
                    <h6>{t("landing.t3")}</h6>
                  ) : (
                    <></>
                  )}
                  <p>{t("landing.t4")}</p>
                  <form onSubmit={handleSubmit(handleRegister)}>
                    <div
                      className="select hero__select"
                      style={{ marginBottom: "2rem" }}
                    >
                      <select
                        id="field-customer"
                        {...register("schoolName", {
                          onChange: (e) => {
                            if (e.currentTarget.value == "zOther") {
                              setOther(true);
                            } else {
                              setOther(false);
                            }
                          },
                          required: {
                            value: true,
                            message: `${
                              i18n.language === "en"
                                ? "Please select your school."
                                : "Veuillez sélectionner votre école."
                            }`,
                          },
                        })}
                      >
                        <option value="">{t("landing.t5")}</option>
                        {schoolsDropDown}
                      </select>
                    </div>
                    {errors.schoolName && (
                      <p className="error-message">
                        <i className="fas fa-exclamation-circle" />{" "}
                        {errors.schoolName.message}
                      </p>
                    )}
                    <button type="submit" className="btn hero__btn">
                      {t("landing.t7")}
                    </button>
                  </form>
                </div>
              </div>
            </div>
            <div className="hero__image hero__desktop">
              <img src="assets/images/hero-borders.png" alt="" className="" />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Landing;
