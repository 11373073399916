import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import questions from "./questions";
import { defaultStorageHelper } from "@spry/campaign-client";
import { publicCampaignClient } from "../../../api";
import Loading from "../../../Components/Loading";
import Modal from "react-modal";
import { useTranslation } from "react-i18next";

const Question = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  let lang = defaultStorageHelper.get("lang");
  let schoolName = defaultStorageHelper.get("schoolName");
  let questionList = questions[lang!];
  const [questionChoice, setQuestionChoice] = useState("");
  const [node, setNode] = useState("q1aa");
  const [questionDetail, setQuestionDetail] = useState<any>();
  const [result, setResult] = useState("");
  const sessionKey = defaultStorageHelper.get("sessionKey");
  const [loading, setLoading] = useState(false);
  const [initialNode, setInitialNode] = useState("");
  const [submissionError, setSubmissionError] = useState(false);
  const [optionSelected, setOptionSelected] = useState(false);
  const [tagLine, setTagLine] = useState("");
  const [resultImage, setResultImage] = useState("");

  useEffect(() => {
    getParticipant();
    getQuestionDetails();
    questionList = questions[lang!];
  }, [lang, i18n.language]);

  useEffect(() => {
    if (!sessionKey) {
      navigate("/");
    }
    defaultStorageHelper.remove("result");
    defaultStorageHelper.remove("tagLine");
    defaultStorageHelper.remove("resultImage");
    getParticipant();
    getQuestionDetails();
  }, [initialNode]);

  const getParticipant = async () => {
    setLoading(true);
    try {
      const { Item }: any = await publicCampaignClient.call("getParticipant", {
        sessionKey: sessionKey,
        schoolName: schoolName,
      });
      if (Item.trivia.length > 0) {
        setInitialNode(Item.trivia[Item.trivia.length - 1].currentQuestionNode);
      }
    } catch (error) {
      console.log(error);
      setSubmissionError(true);
    }
    setLoading(false);
  };

  const getQuestionDetails = () => {
    if (initialNode !== "") {
      questionList.map((q: any) => {
        setQuestionDetail(q[initialNode.replace(/['"]+/g, "")]);
      });
    } else {
      questionList.map((q: any) => {
        setQuestionDetail(q[node.replace(/['"]+/g, "")]);
      });
    }
  };

  const handleGotoNext = async (e: any) => {
    setLoading(true);
    e.preventDefault();
    setInitialNode("");
    getQuestionDetails();
    setNode("");
    try {
      const response: any = await publicCampaignClient.call("addTrivia", {
        sessionKey: sessionKey,
        question: questionDetail.q,
        answer: questionChoice,
        currentQuestionNode: node,
        schoolName: schoolName,
        questionNumber: questionDetail.questionNumber,
      });
      if (questionDetail?.questionNumber == 5) {
        defaultStorageHelper.set("result", result);
        defaultStorageHelper.set("tagLine", tagLine);
        defaultStorageHelper.set("resultImage", resultImage);
        navigate("/results");
      }
    } catch (error) {
      console.log(error);
      setSubmissionError(true);
    }
    setLoading(false);
  };

  return (
    <div className="main">
      {loading && <Loading />}

      {submissionError ? (
        <div className="main" style={{ minHeight: "100%" }}>
          <section className="section section-full section-main section--center">
            <div className="shell">
              <div className="section__inner section__inner--full">
                <div className="section__content">
                  <Modal
                    isOpen
                    contentLabel="My dialog"
                    ariaHideApp={false}
                    className="popup"
                    portalClassName="modal"
                    onAfterOpen={() =>
                      (document.body.style.overflow = "hidden")
                    }
                    onAfterClose={() =>
                      (document.body.style.overflow = "unset")
                    }
                    style={{
                      overlay: {
                        zIndex: 1043,
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "rgba(0,0,0,0.5)",
                      },
                    }}
                  >
                    <div
                      className="mfp-wrap mfp-close-btn-in mfp-auto-cursor mfp-fade mfp-ready"
                      tabIndex={-1}
                      style={{ overflow: "hidden auto" }}
                    >
                      <div className="mfp-container mfp-ajax-holder mfp-s-ready">
                        <div className="mfp-content">
                          <div className="popup popup--alt popup--small">
                            <div className="popup__inner">
                              <div className="popup__body">
                                <h4>
                                  Something went wrong, please try again later.
                                </h4>
                              </div>
                              <div className="popup__foot">
                                <a
                                  className="btn btn--default btn--primary js-popup-close popup__btn"
                                  onClick={() => navigate("/")}
                                >
                                  OK
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Modal>
                </div>
              </div>
            </div>
          </section>
        </div>
      ) : (
        <></>
      )}

      <section className="section">
        <div className="shell">
          <header className="section__head">
            <span>{questionDetail?.questionNumber}/5</span>
          </header>
          <div className="section__body">
            <div className="section__image">
              <img
                src={`assets/triviaImages/${questionDetail?.image}.svg`}
                alt=""
              />
            </div>
            <div
              className="box"
              style={{
                backgroundImage: 'url("assets/images/box-background.svg")',
              }}
            >
              <h4>{questionDetail?.q}</h4>
            </div>
            <div className="radios">
              <ul>
                {questionDetail?.choices.map((qst: any, idx: any) => {
                  return (
                    <li key={idx}>
                      <div className="radio">
                        <input
                          key={idx}
                          type="radio"
                          name="radio"
                          id={`field-celebrate${idx}`}
                          value={qst}
                          checked={qst === questionChoice}
                          onChange={(e) => {
                            setQuestionChoice(e.currentTarget.defaultValue);
                            setNode(`${questionDetail.nextQuestion[idx]}`);
                            setOptionSelected(true);
                            if (
                              questionDetail.finish &&
                              questionDetail.result
                            ) {
                              setResult(questionDetail.result[idx]);
                              setTagLine(questionDetail.tagLines[idx]);
                              questionDetail.choices[0] ===
                              e.currentTarget.defaultValue
                                ? setResultImage(
                                    `${questionDetail.image}-result.svg`
                                  )
                                : setResultImage(
                                    `${questionDetail.image}-result2.svg`
                                  );
                            }
                          }}
                        />
                        <label htmlFor={`field-celebrate${idx}`}>{qst}</label>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="section__actions">
              <button
                onClick={(e: any) => handleGotoNext(e)}
                className="btn section__btn"
                disabled={optionSelected && node != "" ? false : true}
              >
                {questionDetail?.finish === true
                  ? t("q5a.finish")
                  : t("q1.next")}
              </button>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Question;
