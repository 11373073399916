import { defaultStorageHelper } from "@spry/campaign-client";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { publicCampaignClient } from "../../../api";
import questions from "./questions";
import Modal from "react-modal";
import Loading from "../../../Components/Loading";
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";

const SurveyQuestion = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  let questionList = questions[i18n.language];
  const [question, setQuestion] = useState<any>();
  const sessionKey = defaultStorageHelper.get("sessionKey");
  const schoolName = defaultStorageHelper.get("schoolName");
  const [loading, setLoading] = useState(false);
  const [initialIndex, setInitialIndex] = useState<number>(0);
  const [submissionError, setSubmissionError] = useState(false);
  const [answer, setAnswer] = useState("");
  let lang = defaultStorageHelper.get("lang");

  useEffect(() => {
    getParticipant();
    getQuestionDetails();
    questionList = questions[lang!];
  }, [lang, i18n.language]);

  useEffect(() => {
    if (!sessionKey) {
      navigate("/");
    }
    // defaultStorageHelper.remove("result");
    // defaultStorageHelper.remove("learnMoreLink");
    // defaultStorageHelper.remove("schoolName");
    defaultStorageHelper.remove("schoolLocation");
    defaultStorageHelper.remove("tagLine");
    defaultStorageHelper.remove("resultImage");
    getParticipant();
    getQuestionDetails();
  }, [initialIndex]);

  const getParticipant = async () => {
    setLoading(true);
    try {
      const { Item }: any = await publicCampaignClient.call("getParticipant", {
        sessionKey: sessionKey,
        schoolName: schoolName,
      });
      if (Item.surveys.length > 0) {
        setInitialIndex(Item.surveys.length - 1);
      }
    } catch (error) {
      console.log(error);
      setSubmissionError(true);
    }
    setLoading(false);
  };

  const getQuestionDetails = () => {
    if (initialIndex < 2) {
      setQuestion(questionList[initialIndex].q);
    }
  };

  const handleGotoNext = async (e: any, answer: string) => {
    setLoading(true);
    // e.preventDefault();
    if (initialIndex < 2) {
      setInitialIndex(initialIndex + 1);
    }
    if (initialIndex + 1 == 2) {
      navigate("/survey-complete");
    }
    getQuestionDetails();
    try {
      const response: any = await publicCampaignClient.call("addSurveys", {
        sessionKey: sessionKey,
        question: question,
        answer: answer,
        schoolName: schoolName,
        surveyNumber: questionList[initialIndex].questionNumber,
      });
    } catch (error) {
      console.log(error);
      setSubmissionError(true);
    }
    setLoading(false);
  };

  return (
    <div className="main">
      {loading && <Loading />}

      {submissionError ? (
        <div className="main" style={{ minHeight: "100%" }}>
          <section className="section section-full section-main section--center">
            <div className="shell">
              <div className="section__inner section__inner--full">
                <div className="section__content">
                  <Modal
                    isOpen
                    contentLabel="My dialog"
                    ariaHideApp={false}
                    className="popup"
                    portalClassName="modal"
                    onAfterOpen={() =>
                      (document.body.style.overflow = "hidden")
                    }
                    onAfterClose={() =>
                      (document.body.style.overflow = "unset")
                    }
                    style={{
                      overlay: {
                        zIndex: 1043,
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "rgba(0,0,0,0.5)",
                      },
                    }}
                  >
                    <div
                      className="mfp-wrap mfp-close-btn-in mfp-auto-cursor mfp-fade mfp-ready"
                      tabIndex={-1}
                      style={{ overflow: "hidden auto" }}
                    >
                      <div className="mfp-container mfp-ajax-holder mfp-s-ready">
                        <div className="mfp-content">
                          <div className="popup popup--alt popup--small">
                            <div className="popup__inner">
                              <div className="popup__body">
                                <h4>
                                  Something went wrong, please try again later.
                                </h4>
                              </div>
                              <div className="popup__foot">
                                <a
                                  className="btn btn--default btn--primary js-popup-close popup__btn"
                                  onClick={() => navigate("/")}
                                >
                                  OK
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Modal>
                </div>
              </div>
            </div>
          </section>
        </div>
      ) : (
        <></>
      )}

      <section className="section section--small">
        <div className="shell">
          <div className="section__body">
            <div className="section__image">
              <img src="assets/images/section-image-4.svg" alt="" />
            </div>
            <div
              className="box"
              style={{
                backgroundImage: 'url("assets/images/box-background.svg")',
              }}
            >
              <h4>{question}</h4>
            </div>
            <div className="section__actions">
              <a
                onClick={(e) => {
                  // setAnswer("Yes");
                  handleGotoNext(e, "Yes");
                }}
                className="btn btn--full section__btn"
              >
                {i18n.language === "en" ? "Yes" : "Oui"}
              </a>
              <a
                onClick={(e) => {
                  // setAnswer("No");
                  handleGotoNext(e, "No");
                }}
                className="btn btn--full section__btn"
              >
                {i18n.language === "en" ? "No" : "Non"}
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default SurveyQuestion;
