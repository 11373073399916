import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Amplify } from "@aws-amplify/core";
import { Authenticator } from "@aws-amplify/ui-react";
import { QueryClient, QueryClientProvider } from "react-query";
import "@aws-amplify/ui-react/styles.css";
import reportWebVitals from "./reportWebVitals";
import App from "./App";
import { COGNITO_CONFIG } from "./Config";
import { handleSessionKey } from "./helpers/querystring";
import handleWwwRedirect from "./helpers/wwwRedirect/handleWwwRedirect";
import "./i18n";

Amplify.configure({
  Auth: COGNITO_CONFIG,
});

handleSessionKey();
handleWwwRedirect();

const queryClient = new QueryClient();

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Authenticator.Provider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Authenticator.Provider>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
