import { defaultStorageHelper } from "@spry/campaign-client";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { publicCampaignClient } from "../../../api";
import Loading from "../../../Components/Loading";
import Modal from "react-modal";
import { t } from "i18next";

const Results = (inventoryCount: any) => {
  const sessionKey = defaultStorageHelper.get("sessionKey");
  const tagLine = defaultStorageHelper.get("tagLine");
  const result = defaultStorageHelper.get("result");
  const resultImage = defaultStorageHelper.get("resultImage");
  const schoolName = defaultStorageHelper.get("schoolName");
  const schoolLocation = defaultStorageHelper.get("schoolLocation");
  const schoolLocationLink = defaultStorageHelper.get("schoolLocationLink");
  const navigate = useNavigate();
  const [submissionError, setSubmissionError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [clickLearnMore, setClickLearnMore] = useState(false);
  const learnMoreLink = defaultStorageHelper.get("learnMoreLink");

  useEffect(() => {
    if (
      !sessionKey ||
      !result ||
      !tagLine ||
      !resultImage ||
      !schoolName ||
      !schoolLocation
    ) {
      navigate("/");
    }
    postResult();
  }, []);

  const postResult = () => {
    setLoading(true);
    try {
      const res: any = publicCampaignClient.call("result", {
        sessionKey: sessionKey,
        result: result,
        schoolName: schoolName,
      });
    } catch (error) {
      console.log(error);
      setSubmissionError(true);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (clickLearnMore == true) {
      postLearnMore();
    }
  }, [clickLearnMore]);

  const postLearnMore = async () => {
    setLoading(true);
    try {
      const res: any = publicCampaignClient.call("result", {
        sessionKey: sessionKey,
        result: result,
        schoolName: schoolName,
        learnMoreLink: learnMoreLink,
      });
    } catch (error) {
      console.log(error);
      setSubmissionError(true);
    }
    setLoading(false);
  };

  return (
    <div className="main">
      {loading && <Loading />}

      {submissionError ? (
        <div className="main" style={{ minHeight: "100%" }}>
          <section className="section section-full section-main section--center">
            <div className="shell">
              <div className="section__inner section__inner--full">
                <div className="section__content">
                  <Modal
                    isOpen
                    contentLabel="My dialog"
                    ariaHideApp={false}
                    className="popup"
                    portalClassName="modal"
                    onAfterOpen={() =>
                      (document.body.style.overflow = "hidden")
                    }
                    onAfterClose={() =>
                      (document.body.style.overflow = "unset")
                    }
                    style={{
                      overlay: {
                        zIndex: 1043,
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "rgba(0,0,0,0.5)",
                      },
                    }}
                  >
                    <div
                      className="mfp-wrap mfp-close-btn-in mfp-auto-cursor mfp-fade mfp-ready"
                      tabIndex={-1}
                      style={{ overflow: "hidden auto" }}
                    >
                      <div className="mfp-container mfp-ajax-holder mfp-s-ready">
                        <div className="mfp-content">
                          <div className="popup popup--alt popup--small">
                            <div className="popup__inner">
                              <div className="popup__body">
                                <h4>
                                  Something went wrong, please try again later.
                                </h4>
                              </div>
                              <div className="popup__foot">
                                <a
                                  className="btn btn--default btn--primary js-popup-close popup__btn"
                                  onClick={() => navigate("/")}
                                >
                                  OK
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Modal>
                </div>
              </div>
            </div>
          </section>
        </div>
      ) : (
        <></>
      )}

      <section className="section-result">
        <div className="shell">
          <div className="section__inner">
            <header className="section__head">
              <h4>{t("results.hereis")}</h4>
              <p>{t("results.fortaking")}</p>
            </header>
            <div className="section__body">
              <figure className="section__image">
                <img src={`assets/triviaImages/${resultImage}`} alt="" />
              </figure>
              <h4>{result}</h4>
              <p>{tagLine}</p>
              {(inventoryCount && inventoryCount.inventoryCount <= 0) ||
              schoolName === "zOther" ? (
                <></>
              ) : (
                <div
                  className="box box--large"
                  style={{
                    backgroundImage:
                      'url("assets/images/box-background-2.svg")',
                  }}
                >
                  <h4>{t("results.hereis1")}</h4>
                  <p>{t("results.fortaking1")}</p>
                  <h6>{t("results.one")}</h6>
                  <figure className="box__image">
                    <img src="assets/images/dd-gift-card.svg" alt="" />
                  </figure>
                  <p>
                    {t("results.toget")}{" "}
                    {schoolLocationLink !== "" ? (
                      <a href={`${schoolLocationLink}`} target="__blank">
                        {`${schoolName}`}, {schoolLocation}
                      </a>
                    ) : (
                      schoolLocation
                    )}{" "}
                    {t("results.toget1")}
                  </p>
                  <p>
                    <small>{t("results.whilesupplies")} </small>
                  </p>
                </div>
              )}
              <h4>{t("results.takeaquick")}</h4>
              <p>{t("results.wouldyou")}</p>
              <div className="section__actions">
                <a
                  onClick={() => navigate("/survey-question")}
                  className="btn section__btn"
                >
                  {t("results.takethesurvey")}
                </a>
              </div>
              <h4>{t("results.doyouwant")}</h4>
              <p>{t("results.yourrbc")}</p>
              <div
                className="section__actions"
                onClick={() => {
                  setClickLearnMore(true);
                }}
              >
                <a
                  href={`${learnMoreLink}`}
                  target="__blank"
                  className="btn btn--yellow section__btn"
                >
                  {t("results.learnmore")}
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Results;
