import {
  GetStateResult,
  sleep,
  defaultStorageHelper,
} from "@spry/campaign-client";
import { campaignClient, publicCampaignClient } from "../../api";
import { local_campaignState, isLocal } from "../../Config/local";

let campaignState: GetStateResult | null = isLocal ? local_campaignState : null;
let getCampaignStateCached: Promise<GetStateResult> =
  getCampaignStateUntilSuccess();

async function getCampaignStateUntilSuccess(): Promise<GetStateResult> {
  let wait = 100;
  while (true) {
    if (campaignState) {
      return campaignState;
    }
    try {
      campaignState = await publicCampaignClient.getState();
      defaultStorageHelper.set("LastKnownState", JSON.stringify(campaignState));
      return campaignState;
    } catch (e) {}
    await sleep(wait);
    wait *= 2 + 2000;
    if (wait > 60000) {
      wait = 60000;
    }
  }
}

export async function fetchCampaignState() {
  return await getCampaignStateCached;
}
export function getKnownCampaignState() {
  return campaignState || null;
}
