import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import { useRequiredCampaignState } from "../../helpers/campaignStates";

export default function Open() {
  useRequiredCampaignState("open");
  const { t } = useTranslation();
  return (
    <div className="wrapper">
      <Header />
      <Outlet />
      <Footer />
    </div>
  );
}
