import React from "react";
import { useNavigate } from "react-router-dom";
import { useStores } from "../../stores";

const AdminHeader = () => {
  const { authStore } = useStores();
  const navigate = useNavigate();

  const logOut = async () => {
    try {
      await authStore.logout();
      navigate("/admin", { replace: true });
    } catch (error) {
      console.error("error signing out: ", error);
    }
  };

  return (
    <header className="header header--small">
      <div className="shell">
        <div className="header__inner">
          <a className="logo-small">
            <img src="assets/images/logo-small.png" alt="" width={65} />
          </a>
          <a
            onClick={(e) => {
              e.preventDefault();
              logOut();
            }}
          >
            Logout
          </a>
        </div>
      </div>
    </header>
  );
};

export default AdminHeader;
