import React from "react";
import AuthStore from "./AuthStore";

class RootStore {
  public authStore;
  constructor() {
    this.authStore = new AuthStore();
  }
}

export const StoreContext = React.createContext(new RootStore());

export const useStores = () => React.useContext(StoreContext);
