const questions: any = {
  en: [
    {
      q1aa: {
        q: "It’s a special occasion. Where do you go?",
        choices: ["Celebrate at home.", "Go out with friends."],
        nextQuestion: ["q2aa", "q2ba"],
        questionNumber: 1,
        start: true,
        image: "q1aa",
      },

      q2aa: {
        q: "You’re buying a new phone. What’s your priority?",
        choices: [
          "An older model that gives me what I need.",
          "I need the newest tech.",
        ],
        nextQuestion: ["q3aa", "q3ba"],
        questionNumber: 2,
        image: "q2aa",
      },

      q2ba: {
        q: "What is your approach to planning an outing with friends?",
        choices: [
          "I stick to a budget and keep it affordable.",
          "I’ll spend a bit extra to make the most of my excursion.",
        ],
        nextQuestion: ["q3ba", "q3ca"],
        questionNumber: 2,
        image: "q2ba",
      },

      q3aa: {
        q: "What is your approach to buying new clothes?",
        choices: [
          "Whatever is on-sale. The quality is just as good.",
          "Always buy my favourite brands.",
        ],
        nextQuestion: ["q4aa", "q4ba"],
        questionNumber: 3,
        image: "q3aa",
      },

      q3ba: {
        q: "You go to the grocery store. What’s your approach?",
        choices: [
          "Stick to the list and only get what I was sent for.",
          "I have a list but we all need a few treats.",
          "I’ll buy whatever looks good!",
        ],
        nextQuestion: ["q4ba", "q4ca", "q4da"],
        questionNumber: 3,
        image: "q3ba",
      },

      q3ca: {
        q: "You see new shoes that you like. Do you get them?",
        choices: [
          "Only if I need them and it is good value.",
          "Why not? I have to look good for my friends.",
        ],
        nextQuestion: ["q4ca", "q4da"],
        questionNumber: 3,
        image: "q3ca",
      },

      q4aa: {
        q: "What does your phone bill include?",
        choices: ["The basic plan.", "Unlimited data and talk."],
        nextQuestion: ["q5aa", "q5ba"],
        questionNumber: 4,
        image: "q4aa",
      },

      q4ba: {
        q: "Do you bring your lunch to school?",
        choices: [
          "Yes. It’s a great way to save money.",
          "Not often. Purchasing my lunch is more convenient.",
        ],
        nextQuestion: ["q5ba", "q5ca"],
        questionNumber: 4,
        image: "q4ba",
      },

      q4ca: {
        q: "What is more important when purchasing a new backpack?",
        choices: [
          "A backpack that will hold all my books and last the year.",
          "The aesthetics - It has to look good.",
        ],
        nextQuestion: ["q5aa", "q5da"],
        questionNumber: 4,
        image: "q4ca",
      },

      q4da: {
        q: "How do you save for your future?",
        choices: [
          "I regularly set aside money into a savings account.",
          "I’ll save if there’s money left over.",
        ],
        nextQuestion: ["q5ca", "q5da"],
        questionNumber: 4,
        image: "q4da",
      },

      q5aa: {
        q: "What do you look for when you need to buy a new bike?",
        choices: [
          "Top quality. I’ll save money in the long run.",
          "Cost is the biggest factor.",
        ],
        nextQuestion: "",
        questionNumber: 5,
        image: "q5aa",
        finish: true,
        result: ["Proficient Planner", "Sound Saver"],
        tagLines: ["Future Focused.", "Thrifty and Nifty."],
      },

      q5ba: {
        q: "You win $300, what do you do with it?",
        choices: [
          "I deposit it right away.",
          "I already know how I’m going to spend it.",
        ],
        nextQuestion: "",
        questionNumber: 5,
        image: "q5ba",
        finish: true,
        result: ["Sound Saver", "Master of Moderation"],
        tagLines: ["Thrifty and Nifty.", "Spend a Little, Save a Little."],
      },

      q5ca: {
        q: "You’re heading to your favourite musician’s concert. Where are your seats?",
        choices: ["Wherever I can find the best deal.", "Front and centre."],
        nextQuestion: "",
        questionNumber: 5,
        image: "q5ca",
        finish: true,
        result: ["Master of Moderation", "Brilliant Compromiser"],
        tagLines: ["Spend a Little, Save a Little.", "Quality Over Quantity."],
      },

      q5da: {
        q: "You have a big purchase ahead of you. How do you plan for it?",
        choices: [
          "I’ll do my research to get the best quality.",
          "If I see what I like, I go for it.",
        ],
        nextQuestion: "",
        questionNumber: 5,
        image: "q5da",
        finish: true,
        result: ["Brilliant Compromiser", "Free Spirit"],
        tagLines: ["Quality over Quantity.", "Live Life Large."],
      },
    },
  ],

  fr: [
    {
      q1aa: {
        q: "Il s’agit d’une occasion particulière. Quel est votre objectif ?",
        choices: ["Célébrer à la maison.", "Sortir avec des amis."],
        nextQuestion: ["q2aa", "q2ba"],
        questionNumber: 1,
        start: true,
        image: "q1aa",
      },

      q2aa: {
        q: "Vous achetez un nouveau téléphone. Quelle est votre priorité ?",
        choices: [
          "Un ancien modèle qui offre ce dont j’ai besoin.",
          "J’ai besoin de la plus récente technologie.",
        ],
        nextQuestion: ["q3aa", "q3ba"],
        questionNumber: 2,
        image: "q2aa",
      },

      q2ba: {
        q: "Quelle est votre priorité lorsque vous planifiez une sortie entre amis ?",
        choices: [
          "Je m’en tiens à un budget à un niveau abordable.",
          "Je dépense un peu plus pour profiter au maximum de ce moment.",
        ],
        nextQuestion: ["q3ba", "q3ca"],
        questionNumber: 2,
        image: "q2ba",
      },

      q3aa: {
        q: "Quelle est votre priorité lorsque vous achetez de nouveaux vêtements ?",
        choices: [
          "J’achète seulement des articles en solde. La qualité est tout aussi bonne.",
          "J’achète toujours mes marques préférées.",
        ],
        nextQuestion: ["q4aa", "q4ba"],
        questionNumber: 3,
        image: "q3aa",
      },

      q3ba: {
        q: "Vous allez à l’épicerie. Comment vous y prenez-vous ?",
        choices: [
          "Je m’en tiens à ma liste et j’achète seulement ce que je suis venu(e) chercher.",
          "J’ai une liste, mais nous avons tous besoin de gâteries.",
          "J’achète tout ce qui me semble bon !",
        ],
        nextQuestion: ["q4ba", "q4ca", "q4da"],
        questionNumber: 3,
        image: "q3ba",
      },

      q3ca: {
        q: "Vous voyez de nouvelles chaussures qui vous plaisent bien. Allez-vous les acheter ?",
        choices: [
          "Seulement si j’en ai besoin et si elles offrent un bon rapport qualité-prix.",
          "Ah bon, et pourquoi ? Je dois bien paraître pour mes amis.",
        ],
        nextQuestion: ["q4ca", "q4da"],
        questionNumber: 3,
        image: "q3ca",
      },

      q4aa: {
        q: "Que comprend votre facture de téléphone ?",
        choices: [
          "Le forfait de base.",
          "Des données et des conversations illimitées.",
        ],
        nextQuestion: ["q5aa", "q5ba"],
        questionNumber: 4,
        image: "q4aa",
      },

      q4ba: {
        q: "Apportez-vous votre lunch à l’école?",
        choices: [
          "Oui, C’est une excellente façon d’économiser.",
          "Pas souvent. Il est plus pratique pour moi d’acheter mon repas.",
        ],
        nextQuestion: ["q5ba", "q5ca"],
        questionNumber: 4,
        image: "q4ba",
      },

      q4ca: {
        q: "Qu’est-ce qui est le plus important quand vous achetez un nouveau sac à dos ?",
        choices: [
          "Un sac à dos qui contiendra tous mes livres et qui résistera toute l’année.",
          "L’esthétique – Il doit être beau.",
        ],
        nextQuestion: ["q5aa", "q5da"],
        questionNumber: 4,
        image: "q4ca",
      },

      q4da: {
        q: "De quelle manière épargnez-vous pour votre avenir ?",
        choices: [
          "Je mets régulièrement de l’argent de côté sur un compte d’épargne.",
          "J’économise seulement s’il me reste de l’argent.",
        ],
        nextQuestion: ["q5ca", "q5da"],
        questionNumber: 4,
        image: "q4da",
      },

      q5aa: {
        q: "Que recherchez-vous au moment d’acheter une nouvelle bicyclette ?",
        choices: [
          "Une qualité supérieure. J’épargnerai de l’argent au bout du compte.",
          "Le coût est le principal facteur.",
        ],
        nextQuestion: "",
        questionNumber: 5,
        image: "q5aa",
        finish: true,
        result: ["Planificateur aguerri", "Épargnant averti"],
        tagLines: ["Orienté vers l’Avenir.", "Économe et Modeste."],
      },

      q5ba: {
        q: "Vous gagnez 300 $, que faites-vous de cet argent ?",
        choices: [
          "Je le dépose tout de suite.",
          "Je sais déjà comment je vais le dépenser.",
        ],
        nextQuestion: "",
        questionNumber: 5,
        image: "q5ba",
        finish: true,
        result: ["Épargnant averti", "Maître de la Modération"],
        tagLines: [
          "Économe et Modeste.",
          "Dépenser un Peu, Économiser un Peu.",
        ],
      },

      q5ca: {
        q: "Vous assistez au concert de votre musicien préféré. Quelles places choisissez-vous ?",
        choices: [
          "Celles qui sont offertes au meilleur prix.",
          "Devant et au centre.",
        ],
        nextQuestion: "",
        questionNumber: 5,
        image: "q5ca",
        finish: true,
        result: ["Maître de la Modération", "Héros du compromis"],
        tagLines: [
          "Dépenser un Peu, Économiser un Peu.",
          "La qualité avant la quantité.",
        ],
      },

      q5da: {
        q: "Vous devez effectuer un achat important. Comment planifiez-vous cet achat ?",
        choices: [
          "Je fais des recherches pour obtenir la meilleure qualité.",
          "Si je trouve ce que j’aime, je n’hésite pas.",
        ],
        nextQuestion: "",
        questionNumber: 5,
        image: "q5da",
        finish: true,
        result: ["Héros du compromis", "Esprit libre"],
        tagLines: ["La qualité avant la quantité.", "Vivre pleinement sa vie."],
      },
    },
  ],
};

export default questions;
