import { defaultStorageHelper } from "@spry/campaign-client";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { publicCampaignClient } from "../../../api";

const SurveyComplete = (inventoryCount: any) => {
  const navigate = useNavigate();
  const sessionKey = defaultStorageHelper.get("sessionKey");
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const result = defaultStorageHelper.get("result");
  const [submissionError, setSubmissionError] = useState(false);
  const [clickSurveyCompleteLink, setClickSurveyCompleteLink] = useState(false);
  const [clickLearnMore, setClickLearnMore] = useState(false);
  const learnMoreLink = defaultStorageHelper.get("learnMoreLink");
  const schoolName = defaultStorageHelper.get("schoolName");

  useEffect(() => {
    if (!sessionKey || !learnMoreLink) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    if (clickLearnMore == true) {
      postLearnMore();
    }
  }, [clickLearnMore]);

  const postLearnMore = async () => {
    setLoading(true);
    try {
      const res: any = publicCampaignClient.call("result", {
        sessionKey: sessionKey,
        result: result,
        schoolName: schoolName,
        learnMoreLink: learnMoreLink,
      });
    } catch (error) {
      console.log(error);
      setSubmissionError(true);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (clickSurveyCompleteLink == true) {
      postSurveyCompleteLink();
    }
  }, [clickSurveyCompleteLink]);

  const postSurveyCompleteLink = async () => {
    setLoading(true);
    try {
      const res: any = publicCampaignClient.call("result", {
        sessionKey: sessionKey,
        result: result,
        schoolName: schoolName,
        surveyCompleteLink:
          "https://www.rbcroyalbank.com/student-solution/index.html",
      });
    } catch (error) {
      console.log(error);
      setSubmissionError(true);
    }
    setLoading(false);
  };

  return (
    <div className="main">
      <section className="section section--small section--different">
        <div className="shell">
          <div className="section__body">
            <div className="section__image">
              <img src="assets/images/check.svg" alt="" />
            </div>
            <h4 style={{ color: "#0a51a5" }}>{t("complete.thanks")}</h4>
            {inventoryCount && inventoryCount.inventoryCount <= 0 ? (
              <></>
            ) : (
              <p>{t("complete.t1")}</p>
            )}
            <p>
              {t("complete.t2")}
              <a
                href={t("links.surveyCompleteLink")}
                onClick={() => {
                  setClickSurveyCompleteLink(true);
                }}
                style={{ color: "#0a51a5" }}
                target="__blank"
              >
                {t("links.surveyCompleteLink")}
              </a>
            </p>
            {/* <div className="section__actions">
              <a onClick={() => navigate("/")} className="btn  section__btn">
                {t("complete.retake")}{" "}
              </a>
            </div> */}
            <h4>{t("results.doyouwant")}</h4>
            <p>{t("results.yourrbc")}</p>
            <div
              className="section__actions"
              onClick={() => {
                setClickLearnMore(true);
              }}
            >
              <a
                href={`${learnMoreLink}`}
                target="__blank"
                className="btn btn--yellow section__btn"
              >
                {t("results.learnmore")}
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default SurveyComplete;
