import React from "react";
import { useTranslation } from "react-i18next";

const Header = () => {
  const { i18n } = useTranslation();

  return (
    <header className="header">
      <div className="shell">
        <div className="header__inner">
          <a href="/" className="logo">
            <img
              src={`assets/images/logo${i18n.language == "en" ? "" : "fr"}.png`}
              alt=""
              width={207}
            />
          </a>
        </div>
      </div>
    </header>
  );
};

export default Header;
