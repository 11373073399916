const schools: any = {
  en: [
    {
      frLocationLink: "https://maps.rbcroyalbank.com/BC-VANCOUVER-branch-3335",
      locationLink: "https://maps.rbcroyalbank.com/BC-VANCOUVER-branch-3335",
      schoolName: "UBC",
      location: "The Nest",
      learnMore:
        "https://services.rbc.com/EventRegistration/index.php?en&so=style&tr=3335",
    },
    {
      frLocationLink: "https://maps.rbcroyalbank.com/BC-VANCOUVER-branch-3760",
      locationLink: "https://maps.rbcroyalbank.com/BC-VANCOUVER-branch-3760",
      schoolName: "Langara College",
      location: "A Building",
      learnMore:
        "https://services.rbc.com/EventRegistration/index.php?en&so=style&tr=3760",
    },
    {
      frLocationLink: "https://maps.rbcroyalbank.com/BC-KELOWNA-branch-3341",
      locationLink: "https://maps.rbcroyalbank.com/BC-KELOWNA-branch-3341",
      schoolName: "UBC Okanagan",
      location: "University Centre",
      learnMore:
        "https://services.rbc.com/EventRegistration/index.php?en&so=style&tr=3341",
    },
    {
      frLocationLink: "https://maps.rbcroyalbank.com/BC-KELOWNA-branch-4326",
      locationLink: "https://maps.rbcroyalbank.com/BC-KELOWNA-branch-4326",
      schoolName: "Okanagan College",
      location: "Centre for Learning Building",
      learnMore:
        "https://services.rbc.com/EventRegistration/index.php?en&so=style&tr=4326",
    },
    {
      frLocationLink: "https://maps.rbcroyalbank.com/AB-CALGARY-branch-784",
      locationLink: "https://maps.rbcroyalbank.com/AB-CALGARY-branch-784",
      schoolName: "University of Calgary",
      location: "MacEwan Hall",
      learnMore:
        "https://services.rbc.com/EventRegistration/index.php?en&so=style&tr=784",
    },
    {
      frLocationLink: "https://maps.rbcroyalbank.com/AB-EDMONTON-branch-3286",
      locationLink: "https://maps.rbcroyalbank.com/AB-EDMONTON-branch-3286",
      schoolName: "University of Alberta",
      location: "the HUB Mall",
      learnMore:
        "https://services.rbc.com/EventRegistration/index.php?en&so=style&tr=3286",
    },
    {
      frLocationLink: "https://maps.rbcroyalbank.com/AB-LETHBRIDGE-branch-4145",
      locationLink: "https://maps.rbcroyalbank.com/AB-LETHBRIDGE-branch-4145",
      schoolName: "University of Lethbridge",
      location: "Student Union Building",
      learnMore:
        "https://services.rbc.com/EventRegistration/index.php?en&so=style&tr=4145",
    },
    {
      frLocationLink: "https://maps.rbcroyalbank.com/AB-EDMONTON-branch-4088",
      locationLink: "https://maps.rbcroyalbank.com/AB-EDMONTON-branch-4088",
      schoolName: "MacEwan University",
      location: "SAMU Building, Main Floor",
      learnMore:
        "https://services.rbc.com/EventRegistration/index.php?en&so=style&tr=4088",
    },
    {
      frLocationLink: "https://maps.rbcroyalbank.com/AB-RED%20DEER-branch-4581",
      locationLink: "https://maps.rbcroyalbank.com/AB-RED%20DEER-branch-4581",
      schoolName: "Red Deer College",
      location: "Red Deer Bottling Forum",
      learnMore:
        "https://services.rbc.com/EventRegistration/index.php?en&so=style&tr=4581",
    },
    {
      frLocationLink:
        "https://maps.rbcroyalbank.com/ON-THUNDER%20BAY-branch-4188",
      locationLink:
        "https://maps.rbcroyalbank.com/ON-THUNDER%20BAY-branch-4188",
      schoolName: "Lakehead University",
      location: "University Centre, 2nd floor",
      learnMore:
        "https://services.rbc.com/EventRegistration/index.php?en&so=style&tr=4188",
    },
    {
      frLocationLink: "https://maps.rbcroyalbank.com/ON-HAMILTON-branch-3652",
      locationLink: "https://maps.rbcroyalbank.com/ON-HAMILTON-branch-3652",
      schoolName: "McMaster University",
      location: "in the DBAC",
      learnMore:
        "https://services.rbc.com/EventRegistration/index.php?en&so=style&tr=3652",
    },
    {
      frLocationLink: "https://maps.rbcroyalbank.com/ON-WATERLOO-branch-3765",
      locationLink: "https://maps.rbcroyalbank.com/ON-WATERLOO-branch-3765",
      schoolName: "Wilfrid Laurier University",
      location: "Arts E - Lower Concourse",
      learnMore:
        "https://services.rbc.com/EventRegistration/index.php?en&so=style&tr=3765",
    },
    {
      frLocationLink: "https://maps.rbcroyalbank.com/ON-GUELPH-branch-3351",
      locationLink: "https://maps.rbcroyalbank.com/ON-GUELPH-branch-3351",
      schoolName: "University of Guelph",
      location: "Guelph Gryphons Athletic Centre",
      learnMore:
        "https://services.rbc.com/EventRegistration/index.php?en&so=style&tr=3351",
    },
    {
      frLocationLink:
        "https://maps.rbcroyalbank.com/ON-NORTH%20YORK-branch-3481",
      locationLink: "https://maps.rbcroyalbank.com/ON-NORTH%20YORK-branch-3481",
      schoolName: "York University",
      location: "in the Quad",
      learnMore:
        "https://services.rbc.com/EventRegistration/index.php?en&so=style&tr=3481",
    },
    {
      frLocationLink: "https://maps.rbcroyalbank.com/ON-TORONTO-branch-3363",
      locationLink: "https://maps.rbcroyalbank.com/ON-TORONTO-branch-3363",
      schoolName: "University of Toronto",
      location: "1st floor, UofT Student Commons",
      learnMore:
        "https://services.rbc.com/EventRegistration/index.php?en&so=style&tr=3363",
    },

    {
      locationLink: "https://maps.rbcroyalbank.com/ON-OTTAWA-branch-3809",
      frLocationLink: "https://maps.rbcroyalbank.com/fr/ON-OTTAWA-branch-3809",
      schoolName: "University of Ottawa",
      location:
        "Jock Turcot University Centre (UCU) / Le Centre Universitaire Jock Turcot (UCU)",
      learnMore:
        "https://services.rbc.com/EventRegistration/index.php?en&so=style&tr=3809",
    },
    {
      locationLink: "https://maps.rbcroyalbank.com/QC-MONTREAL-branch-3946",
      frLocationLink:
        "https://maps.rbcroyalbank.com/fr/QC-MONTREAL-branch-3946",
      schoolName: "McGill University",
      location:
        "University & Sherbrooke West / Rue University  & Rue Sherbrooke Ouest",
      learnMore:
        "https://services.rbc.com/EventRegistration/index.php?en&so=style&tr=3946",
    },
    {
      locationLink: "https://maps.rbcroyalbank.com/QC-MONTREAL-branch-4257",
      frLocationLink:
        "https://maps.rbcroyalbank.com/fr/QC-MONTREAL-branch-4257",
      schoolName: "Concordia University",
      location: "the EV Building / Au pavillon EV de Concordia",
      learnMore:
        "https://services.rbc.com/EventRegistration/index.php?en&so=style&tr=4257",
    },

    {
      frLocationLink: "https://maps.rbcroyalbank.com/NL-ST%20JOHNS-branch-4504",
      locationLink: "https://maps.rbcroyalbank.com/NL-ST%20JOHNS-branch-4504",
      schoolName: "Memorial University",
      location: "University Centre",
      learnMore:
        "https://services.rbc.com/EventRegistration/index.php?en&so=style&tr=4504",
    },
    {
      frLocationLink: "https://maps.rbcroyalbank.com/ON-WINDSOR-branch-2085",
      locationLink: "https://maps.rbcroyalbank.com/ON-WINDSOR-branch-2085",
      schoolName: "University of Windsor",
      location: "CAW Student Building",
      learnMore:
        "https://services.rbc.com/EventRegistration/index.php?en&so=style&tr=2085",
    },
    {
      frLocationLink: "https://maps.rbcroyalbank.com/NS-HALIFAX-branch-3314",
      locationLink: "https://maps.rbcroyalbank.com/NS-HALIFAX-branch-3314",
      schoolName: "Dalhousie University",
      location: "Dalplex",
      learnMore:
        "https://services.rbc.com/EventRegistration/index.php?en&so=style&tr=3314",
    },
    {
      frLocationLink: "",
      locationLink: "",
      schoolName: "University of Saskatchewan",
      location: "Place Riel Student Centre, Lower Level",
      learnMore:
        "https://services.rbc.com/EventRegistration/index.php?en&so=style&tr=7488",
    },
    {
      frLocationLink: "",
      locationLink: "",
      schoolName: "University of the Fraser Valley",
      location: "Academic Loung in Building A, near the bus loop",
      learnMore:
        "https://services.rbc.com/EventRegistration/index.php?en&so=style&tr=1278",
    },
    {
      frLocationLink: "",
      locationLink: "",
      schoolName: "Sheridan College Brampton",
      location: "Davis Campus, C-Wing",
      learnMore:
        "https://services.rbc.com/EventRegistration/index.php?en&so=style&tr=8382",
    },
    {
      frLocationLink: "https://maps.rbcroyalbank.com/AB-LETHBRIDGE-branch-4145",
      locationLink: "https://maps.rbcroyalbank.com/AB-LETHBRIDGE-branch-4145",
      schoolName: "Lethbridge College",
      location: "Anderson Hall",
      learnMore:
        "https://services.rbc.com/EventRegistration/index.php?en&so=style&tr=4145",
    },
    {
      frLocationLink: "",
      locationLink: "",
      schoolName: "zOther",
      frSchoolName: "zAutre",
      location: "your closest RBC On-Campus branch",
      learnMore:
        "https://services.rbc.com/EventRegistration/index.php?so=style&cat=Youth",
    },
  ],
};

export default schools;
