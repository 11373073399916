import { defaultStorageHelper } from "@spry/campaign-client";
import React from "react";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t, i18n } = useTranslation();

  const handleLanguageChange = (e: any, lang: string) => {
    e.preventDefault();
    defaultStorageHelper.set("lang", lang);
    i18n.changeLanguage(lang);
  };

  return (
    <footer className="footer">
      <div className="shell">
        <div className="footer__inner">
          <p>{t("Footer.royal")}</p>
          <ul>
            <li>
              <a href={t("links.privacy")} target="__blank">
                {t("Footer.privacy")}
              </a>
            </li>
            <li>
              <a
                onClick={(e) =>
                  handleLanguageChange(e, i18n.language == "en" ? "fr" : "en")
                }
              >
                {i18n.language == "en" ? "Français" : "English"}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
