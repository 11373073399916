const questions: any = {
  en: [
    {
      q: "Did this quiz change how you feel about spending?",
      questionNumber: 1,
      start: true,
    },

    {
      q: "Were you aware that RBC has a branch on campus?",
      questionNumber: 2,
    },
  ],

  fr: [
    {
      q: "Ce jeu-questionnaire a-t-il changé votre perception des dépenses ?",
      questionNumber: 1,
      start: true,
    },

    {
      q: "Saviez-vous que RBC a une succursale sur campus?",
      questionNumber: 2,
    },
  ],
};

export default questions;
